.select-paper {
  border-radius: 6px !important;
  min-width: 188px !important;
  margin-top: 4px;
  box-shadow: none !important;
  border: 1px solid #dadde4;
}

.select-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: white;
  margin: 12px 0 !important;
}

.select-list li {
  font-size: 12px;
  padding: 4px 8px;
  margin: 8px 8px;
}

.select-list li:hover {
  background-color: rgba(74, 195, 101, 0.1);
  border-radius: 4px;
}

.select-list li.Mui-selected {
  font-weight: 600;
  background-color: #ffffff;
}
.select-list li.Mui-selected:hover {
  background-color: rgba(74, 195, 101, 0.1);
  border-radius: 4px;
}
