@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Montserrat Bold";
  src: url(./assets/fonts/Montserrat-Bold.ttf);
}

* {
  font-family: "Montserrat", Arial, sans-serif !important
}

body {
  margin: 0;
}



@keyframes textSlide1 {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-2932px);
  }
}

@keyframes textSlide2 {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(2008px);
  }
}

@keyframes textSlide3 {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-2819px);
  }
}
