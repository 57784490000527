.progress-block__item {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 50px; 
  position: relative;
  color: var(--green);
  transition: all 0.1s linear;
  background: white;
  border-radius: 50%;
  cursor: pointer;

  font-weight: 800;
}
.CircularProgressbar .CircularProgressbar-trail {
    stroke: #f5f5f5!important
}
.CircularProgressbar-path {
    stroke: var(--green)!important;
  }
.progress-block__item > span {
  text-align: center;
  position: relative;
  line-height: 80px;
  font-size: 60px;
  display: block;
  color: var(--green);
  transition: all 0.1s linear;
  background: white;
  border-radius: 50%;
  cursor: pointer;

  font-weight: 800;
  width: 80px;
  height: 80px;
  z-index: 20;
}
.progress-block__item .CircularProgressbar {
  transform: scale(1.07) rotate(90deg);
  position: absolute;
  top: 0;

  width: 80px;
  height: 80px;
  left: 0;
}
.progress-block__item:hover {
  transform: scale(1.3);
}
.progress-block__item.active {
  transform: scale(1.375);
}

@media (max-width: 768px){
  .progress-block__item {
    width: 40px;
    height: 40px;

  }
  .progress-block__item .CircularProgressbar {
    width: 40px;
    height: 40px;

  }
  .progress-block {
    padding: 0!important;
    gap: 25px!important;
  }
  .progress-block__elem{
margin-bottom: 30px!important;
gap: 12px!important;

  }
  .progress-block__elem h5 {
    font-size: 20px!important;
    line-height: 22px!important;
  }
  .progress-block__elem p {
    font-size: 14px!important;
    line-height: 20px!important;
  }
  .progress-block__item span {
    width: 40px;height: 40px;
    font-size: 30px;
    line-height: 40px;
  }
}