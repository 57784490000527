:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;

}

@media (max-width: 768px) {
    .slider-item {
        flex-direction: column;
        max-width: 100%!important;
        min-width: 100%!important;
        gap: 12px!important;
        align-self: stretch;
        >img {
            min-width: 100%!important;
            max-width: 100%!important;
        }
        >div {
            padding: 30px!important;
            gap: 10px!important;
            justify-self: stretch!important;

            >h3 {
                font-size: 20px!important;
                line-height: 22px!important;
            }
            >p {
                font-size: 14px!important;
                line-height: 19px!important;
            }
        }
    }
}

     .Container {
         max-width: 1440px;
         margin: 0 auto;
     }
    .HeaderContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        padding: 0 150px;

        @media (max-width: 1367px) {
            padding: 0 20px;

        }
    }

    .HeaderInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .Image {
        height: 64px;
    }

    .Text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        margin-right: 16px;
        line-height: 24px;
        color: #62686b;
    }


    .TextHead {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        margin-right: 16px;
        line-height: 24px;
        color: #62686b;
    }
    .Phone {
        font-weight: 600;
        text-decoration: none;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1f2021;
    }
    .LoginContainerNew {
        display: flex;
        justify-content: center;
        height: 100vh;
        align-items: center;
    }

    .LoginContainer {
        display: flex;
        padding: 0 150px;

        @media (max-width: 1367px) {
            padding: 0 20px;
        }
        @media (max-width: 1163px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .LoginAuth {
        margin-top: 90px;
        margin-right: 30px;

        @media (max-width: 1163px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 16px;
            margin-right: 0;
        }
    }

    .LoginPreview {
        margin-top: 112px;
        position: relative;

        @media (max-width: 1163px) {
            margin-top: 0;
        }
    }

    .LoginTitle {
        max-width: 500px;
        font-size: 32px;
        font-weight: 700;
        color: #1f2021;
        @media (max-width: 1163px) {
            text-align: center;
        }
    }

    .LoginVideoPreview {
        position: relative;
        width: 579px;
        height: 424px;
    }

    .HumanImage {
        position: absolute;
        bottom: 0;
        left: 455px;
    }

    .PlayVideoButton {
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transition: transform 0.2s;
        transform: translate(-50%, -50%);
    }

    .WrapperAbout {
        width: 100%;
        margin-top: 80px;
    }

    .InfoContainer {
        padding: 0 150px;
        @media (max-width: 1367px) {
            padding: 0 20px;
        }
    }

    .InfoTitle {
        font-size: 28px;
        font-weight: 700;
        color: #1f2021;
        text-align: center;
        max-width: 945px;
        margin: 0 auto;
    }

    .InfoPreviewImages {
        margin: 40px auto 0;
        max-width: 913px;
        width: 100%;
        position: relative;
    }

    .InfoPreviewImage {
        width: 100%;
    }

    .InfoPreviewPlayImage {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -56px;
        margin-top: -56px;
        transition: transform 0.2s;
        cursor: pointer;
    }

    .InfoDescription  {
        margin-top: 64px;
        font-size: 24px;
        font-weight: 700;
        color: #000;
    }

    .InfoCaption {
        margin-top: 24px;
        font-size: 1em;
        font-weight: 400;
        color: #000;
    }

    .InfoCardList {
        margin-top: 56px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 1163px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .Marked {
        color: #FC5E5E;
    }

    .Text {
        height: 75px;
        color: #fff;
        font-size: 1em;
        font-weight: 400;
        text-align: center;
        line-height: 75px;
        background-color: #62686B;

        @media (max-width: 1163px) {
            margin-top: 20px;
        }
    }


    .WrapperHeadBlock {
        width: 100%;
        background-color: #f6fafd;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

